<template>
  <div class="changePassword">
    <!-- 请输入原登录密码 -->
    <div class="cell">
      <van-field
        v-model="oldPassword"
        :type="oldType"
        :name="$t('seeting.changePwd')"
        label=""
        :placeholder="$t('seeting.inputOldPwd')"
        :rules="[{ required: true, message: $t('seeting.inputOldPwd') }]"
      >
        <my-icon
          fontSize="32"
          slot="right-icon"
          :iconName="oldIcon"
          @click="showPwd('old')"
        ></my-icon>
      </van-field>
    </div>
    <!-- 请输入新登录密码 -->
    <div class="cell">
      <van-field
        v-model="newPassword"
        :type="newType"
        :name="$t('seeting.changePwd')"
        label=""
        :placeholder="$t('seeting.inputNewPwd')"
        :rules="[{ required: true, message: $t('seeting.inputNewPwd') }]"
      >
        <my-icon
          fontSize="32"
          slot="right-icon"
          :iconName="newIcon"
          @click="showPwd('new')"
        ></my-icon>
      </van-field>
    </div>
    <!-- 请再次输入新密码 -->
    <div class="cell">
      <van-field
        v-model="newPassword2"
        :type="new2Type"
        :name="$t('seeting.changePwd')"
        label=""
        :placeholder="$t('seeting.inputAgainPwd')"
        :rules="[{ required: true, message: $t('seeting.inputAgainPwd') }]"
      >
        <my-icon
          fontSize="32"
          slot="right-icon"
          :iconName="new2Icon"
          @click="showPwd('new2')"
        ></my-icon>
      </van-field>
    </div>
    <div class="button">
      <van-button size="large" class="btn" @click="save" color="#1AB370">{{
        $t("login.save")
      }}</van-button>
      <van-button
        size="large"
        class="btn bottomBtn"
        @click="back"
        type="default"
        >{{ $t("common.back") }}</van-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      newPassword2: "",
      oldType: "password",
      newType: "password",
      new2Type: "password",
      oldIcon: "icon-mima_bukedu_48_hei",
      newIcon: "icon-mima_bukedu_48_hei",
      new2Icon: "icon-mima_bukedu_48_hei",
    };
  },
  computed: {
    userInfo() {
      this.userName = this.$store.state.user.userInfo.realName;
      return this.$store.state.user.userInfo;
    },
  },
  methods: {
    go(path) {
      this.$router.push({ name: path });
    },
    showPwd(data) {
      if (this[data + "Type"] === "test") {
        this[data + "Type"] = "password";
        this[data + "Icon"] = "icon-mima_bukedu_48_hei";
      } else {
        this[data + "Type"] = "test";
        this[data + "Icon"] = "icon-mima_kedu_48_hei";
      }
    },
    save() {
      console.log(this.newPassword.length > 8, this.newPassword.length < 17);
      if (this.newPassword.length < 8 || this.newPassword.length > 16) {
        this.$toast(this.$t("reg.lengthPwd"));
        return;
      }
      if (this.newPassword !== this.newPassword2) {
        this.$toast(this.$t("reg.somePwd"));
        return;
      }
      if (this.oldPassword === this.newPassword) {
        this.$toast(this.$t("reg.oldNewSome"));
        return;
      }
      sdk.meetSetting
        .changeUserPassword(
          this.userInfo.username,
          this.oldPassword,
          this.newPassword,
          this.userInfo.enterpriseId
        )
        .then((res) => {
          this.$toast(this.$t("seeting.editPwdSuccess"));
          this.$router.replace({ path: "/" });
        })
        .catch((res) => {
          console.log(res);
          if (res.code == 501) {
            this.$toast({
              message: "输入正确的旧密码",
            });
          } else {
            this.$toast({
              message: res.msg,
            });
          }
        });
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped lang='less'>
.changePassword {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fafafa;

  .cell {
    margin-top: 16px;
  }
  .button {
    width: 100%;
    box-sizing: border-box;
    padding: 0 64px;
    margin-top: 80px;
    .btn {
      height: 88px;
    }
    .bottomBtn {
      margin-top: 32px;
    }
  }

  .item {
    background: #fff;
    margin-top: 16px;
    height: 104px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    .tit {
      font-size: 32px;
      color: #333;
    }
    .box {
      display: flex;
      align-items: center;
    }
  }
}
</style>
